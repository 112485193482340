import React from 'react';
import { Typography, Row, Col, Divider, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Link as RouteLink } from '../react-router-wrappers/Link';
import imageDemo from '../assets/icusyntax-example.png';
import { LandingLayout } from './LandingLayout';

const { Title, Paragraph, Link } = Typography;

export const ICUSyntaxLanding: React.FC = () => {
    const intl = useIntl();
    return (
        <LandingLayout>
            <Helmet>
                <title>
                    {intl.formatMessage({ id: 'icuSyntaxLanding.titlePage' })}
                </title>
                <meta name="keywords" content={intl.formatMessage({ id: 'icuSyntaxLanding.meta.keywords'})} />
                <meta
                    name="description"
                    content={intl.formatMessage({ id: 'icuSyntaxLanding.meta.description'})}
                />
            </Helmet>
            <Title style={{ fontSize: '2.4em' }}>
                <FormattedMessage id="icuSyntaxLanding.title" defaultMessage="FormatJS Resources with ICU Syntax" />
            </Title>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12}>
                    <Paragraph style={{ fontSize: '1.4em', lineHeight: 2 }}>
                        <FormattedMessage
                            id="icuSyntaxLanding.description"
                            defaultMessage="Locmat simplifies the process of working with FormatJS resources and the ICU message syntax for internationalization and localization. This powerful feature enables your development team to efficiently manage translations while adhering to the widely adopted ICU standard."
                        />
                    </Paragraph>
                    <Title level={3} style={{ fontSize: '2em' }}>
                        <FormattedMessage id="icuSyntaxLanding.benefits.title" defaultMessage="Benefits" />
                    </Title>
                    <Paragraph style={{ fontSize: '1.2em' }}>
                        <FormattedMessage
                            id="icuSyntaxLanding.benefits.item1"
                            defaultMessage="Easily handle complex language structures such as plurals, gender, and number formatting."
                        />
                    </Paragraph>
                    <Paragraph style={{ fontSize: '1.2em' }}>
                        <FormattedMessage
                            id="icuSyntaxLanding.benefits.item2"
                            defaultMessage="Improve the readability and maintainability of your translation files."
                        />
                    </Paragraph>
                    <Paragraph style={{ fontSize: '1.2em' }}>
                        <FormattedMessage
                            id="icuSyntaxLanding.benefits.item3"
                            defaultMessage="Ensure consistent translation output across various platforms and libraries."
                        />
                    </Paragraph>

                    <RouteLink to={'/auth/login'}>
                        <Button block={true} size={'large'} style={{ margin: '22px 0', height: '3rem' }}>
                            <FormattedMessage id={'icuSyntaxLanding.callToActionFromICU'}/>
                        </Button>
                    </RouteLink>

                    <Divider />
                    <Title level={3}>
                        <FormattedMessage id="icuSyntaxLanding.resources.title" defaultMessage="Related Resources" />
                    </Title>
                    <Paragraph>
                        <FormattedMessage
                            id="icuSyntaxLanding.resources.description"
                            defaultMessage="Locmat supports integration with popular internationalization libraries, providing a seamless experience for managing your translations. Explore the following resources to learn more about using Locmat with your preferred library:"
                        />
                    </Paragraph>
                    <ul>
                        <li>
                            <Link href="https://formatjs.io/" target="_blank">
                                <FormattedMessage id="icuSyntaxLanding.resources.formatjs" defaultMessage="FormatJS Home" />
                            </Link>
                        </li>
                        <li>
                            <Link href="https://github.com/formatjs/formatjs/tree/main/packages/react-intl" target="_blank">
                                <FormattedMessage id="icuSyntaxLanding.resources.reactIntl" defaultMessage="React-Intl Library" />
                            </Link>
                        </li>
                        <li>
                            <Link href="https://github.com/intlify/vue-i18n-next" target="_blank">
                                <FormattedMessage id="icuSyntaxLanding.resources.vueIntl" defaultMessage="Vue-Intl Library" />
                            </Link>
                        </li>
                    </ul>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <img src={imageDemo} style={{ border: '1px solid black' }} alt="ICU Support example"/>
                </Col>
            </Row>
        </LandingLayout>
    );
};
