import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, Row, Col } from 'antd';
import { LandingLayout } from './LandingLayout';
import { Helmet } from 'react-helmet';
import { CallToActionButton } from './CallToActionButton';
const { Title, Paragraph } = Typography;

export const AutoTranslationLanding: React.FC = () => {
    const intl = useIntl();
    return (
        <LandingLayout>
            <Helmet>
                <title>
                    {intl.formatMessage({ id: "autoTranslateLanding.titlePage"})}
                </title>
                <meta
                    name="description"
                    content={intl.formatMessage({ id: "autoTranslateLanding.metaDescription"})}
                />
                <meta
                    name="keywords"
                    content={intl.formatMessage({ id: "autoTranslateLanding.metaKeywords"})}
                />
            </Helmet>
            <Title level={2}>
                <FormattedMessage id="autoTranslateLanding.title"/>
            </Title>
            <Paragraph>
                <FormattedMessage id="autoTranslateLanding.introduction"/>
            </Paragraph>
            <Title level={3}>
                <FormattedMessage id="autoTranslateLanding.benefits"/>
            </Title>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Title level={4}>
                        <FormattedMessage id="autoTranslateLanding.accuracy"/>
                    </Title>
                    <Paragraph>
                        <FormattedMessage id="autoTranslateLanding.accuracyDescription"/>
                    </Paragraph>
                </Col>
                <Col xs={24} md={12}>
                    <Title level={4}>
                        <FormattedMessage id="autoTranslateLanding.speed"/>
                    </Title>
                    <Paragraph>
                        <FormattedMessage id="autoTranslateLanding.speedDescription"/>
                    </Paragraph>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Title level={4}>
                        <FormattedMessage id="autoTranslateLanding.scalability"/>
                    </Title>
                    <Paragraph>
                        <FormattedMessage id="autoTranslateLanding.scalabilityDescription"/>
                    </Paragraph>
                </Col>
                <Col xs={24} md={12}>
                    <Title level={4}>
                        <FormattedMessage id="autoTranslateLanding.customization"/>
                    </Title>
                    <Paragraph>
                        <FormattedMessage id="autoTranslateLanding.customizationDescription"/>
                    </Paragraph>
                </Col>
            </Row>
            <Title level={3}>
                <FormattedMessage id="autoTranslateLanding.getStarted"/>
            </Title>
            <Paragraph>
                <FormattedMessage id="autoTranslateLanding.getStartedDescription"/>
            </Paragraph>
            <CallToActionButton>
                <FormattedMessage id="autoTranslateLanding.signupNow"/>
            </CallToActionButton>
        </LandingLayout>
    );
};
