import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, message, Row, Space, Table, Upload, UploadFile } from 'antd';
import { UploadChangeParam, UploadProps } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { LanguagesSelect } from './LangugagesSelect';
import { getProjectById, saveProjectResources, translateResources } from '../api/TranslationApi';
import { SupportedCodes } from '../logic/supportedLanguages';
import { getUploadedFileContent } from '../logic/пetUploadedFileContent';
import { ValueEditable } from './ValueEditable';
import { getResourcesFromDataSource } from '../logic/getResourcesFromDataSource';
import { downloadObjectAsJson } from '../logic/downloadAsJSON';
import { DataSource, fillDataSource, fillDataSourceTranslations } from '../logic/antTableDataSource';
import { useParams } from 'react-router-dom';
import { Link } from '../react-router-wrappers/Link';
import { useNavigate } from '../react-router-wrappers/useNavigate';
import { sampleResources } from './sampleResources';
import { ChatGptFormModal } from './ChatGptFormModal';

const defaultLangColumns = [
    {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        width: 250,
        editable: false
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        editable: true,
    }
];

const translationColumns = (defaultLang: string, selectedLang: string) => [
    {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        width: 250,
        editable: false
    },
    {
        title: `${defaultLang} value`,
        dataIndex: 'value',
        key: 'value',
        width: 300,
        editable: false
    },
    {
        title: `${selectedLang} value`,
        dataIndex: `${selectedLang}_value`,
        key: `${selectedLang}_value`,
        editable: true
    }
]


function isJsonFile(file: UploadFile) {
    return file.name.endsWith('json') && (file.type?.includes('text/json') || file.type?.includes('application/json'));
}

const validateJson: UploadProps['beforeUpload'] = (file) => {
    if (!isJsonFile(file)) {
        message.error('File type is not supported! Expected JSON file to be uploaded!');
        return Upload.LIST_IGNORE;
    }

    return false;
}

export const ResourcesEditing = () => {
    const intl = useIntl();
    const routeParams = useParams();
    const projectId = routeParams.projectId as string;
    const [resources, setResources] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [isNewProject, setIsNewProject] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [project, setProject] = useState<any>(null);
    const [dataSource, setDataSource] = useState<DataSource[]>([]);
    const [activeLang, setActiveLang] = useState<SupportedCodes>('en');
    const [chatGptContext, setChatGptContext] = useState<string>('');
    const [chatGtpContextModalOpened, setChatGptContextOpened] = useState<boolean>(false);
    const [isMachineTranslationLoading, machineTranslationLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true)
        getProjectById(projectId).then(project => {
            setProject(project);
            if (Object.keys(project.resources || {}).length === 0) {
                setIsNewProject(true);
            }

            const dataSource: DataSource[] = [];
            for (let key in project.resources) {
                if (key === 'en') {
                    setResources(project.resources.en);
                    fillDataSource(project.resources.en, dataSource);
                } else {
                    const translations = project.resources[key];
                    fillDataSourceTranslations(dataSource, key, translations);
                }
            }

            setDataSource(dataSource);
            setIsLoading(false);
        }, (err) => {
            console.error(err);
            navigate('/app/projects')
        });
    }, [navigate, projectId]);

    const fillWithSample = () => {
        setResources({ ...sampleResources });
        const newDs = [...dataSource];
        fillDataSource(sampleResources, newDs);
        setDataSource(newDs);
        setIsNewProject(false);
    };

    const handleChange = (info: UploadChangeParam) => {
        const file = info.file;

        if (file.status === 'removed') {
            return;
        }

        if (!file) {
            message.error('Expected to have exactly one JSON file!');
            return;
        }

        if (!isJsonFile(file)) {
            return;
        }

        getUploadedFileContent(file).then(resource => {
            setResources({ resources, ...resource });
            const newDs = [...dataSource];
            fillDataSource(resource, newDs);
            setDataSource(newDs);
            setIsNewProject(false);
        }).catch(e => {
            message.error('JSON is incorrect. Error details: ' + e.message);
        });
    };

    const handleLangChange = (lang: SupportedCodes) => {
        setActiveLang(lang);
    }

    const fillWithMachineTranslation = async () => {
        try {
            machineTranslationLoading(true);
            const response = await translateResources(resources, activeLang)
            fillDataSourceTranslations(dataSource, activeLang, response.translationResult);
            setDataSource([...dataSource]);
        } catch (e) {
            message.error('Was not able to translate due to error: ' + (e as any).message)
        } finally {
            machineTranslationLoading(false);
        }
    }

    const initiateDownload = () => {
        const resources = getResourcesFromDataSource(dataSource, activeLang);
        downloadObjectAsJson(resources, `resources-${activeLang}.json`);
    };

    const saveChanges = async () => {
        const resources = getResourcesFromDataSource(dataSource, activeLang);
        setIsSaving(true);
        try {
            await saveProjectResources(project, activeLang, resources);
            message.success(intl.formatMessage({ id: 'notification.successfullySaved' }, { lang: activeLang }))
        } catch (e) {
            message.error(intl.formatMessage({ id: 'errors.unableToSaveResources' }))
        } finally {
            setIsSaving(false);
        }
    };
    const handleSave = (row: DataSource) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };

    const cells = (activeLang === 'en' ? defaultLangColumns : translationColumns('en', activeLang)).map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            render: (text: string, record: any) => {
                return <ValueEditable text={text} record={record} handleSave={handleSave} keyToUpdate={col.key} chatGptContext={chatGptContext}/>;
            }
        };
    });

    const askForContext = () => {
        setChatGptContextOpened(true);
    };

    const onChatGptSubmit = (chatGptContext: string) => {
        setChatGptContext(chatGptContext);
        setChatGptContextOpened(false);
    }

    return <>
        <Row style={{ marginTop: '30px' }}>
            <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/app/projects"><FormattedMessage id={'projects.projects'}/></Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item><FormattedMessage id={'projects.projectWithName'}
                                                   values={{ projectName: project ? project.name : '' }}/></Breadcrumb.Item>
            </Breadcrumb>
        </Row>
        <Row>
            <Col span={24}>
                <Space style={{ lineHeight: '120px' }}>
                    {isNewProject ? <Button onClick={fillWithSample}>
                        <FormattedMessage id={'resourceEditing.fillWithSample'}/>
                    </Button> : null}
                    <Upload beforeUpload={validateJson} onChange={handleChange} accept={'application/json'}
                            multiple={false} showUploadList={false}>
                        <Button><UploadOutlined/><FormattedMessage id={'resourceEditing.uploadResources'}/></Button>
                    </Upload>
                    <LanguagesSelect onLangChange={handleLangChange}/>
                    {activeLang !== 'en' && <Button onClick={fillWithMachineTranslation}
					                                loading={isMachineTranslationLoading}><FormattedMessage
						id={'resourceEditing.fillWithMachineTranslation'}/></Button>}
                    <Button disabled={activeLang === 'en'} onClick={initiateDownload}>
                        <FormattedMessage id={'resourceEditing.downloadLangResources'} values={{ lang: activeLang }}/>
                    </Button>
                    <Button onClick={saveChanges} loading={isSaving}>
                        <FormattedMessage id={'resourceEditing.saveChanges'}/>
                    </Button>
                    <Button onClick={askForContext} title={intl.formatMessage({ id: 'resourceEditing.chatGtpContextExplanation' })}>
                        <FormattedMessage id={'resourceEditing.askChatGptContext'}/>
                    </Button>
                </Space>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table dataSource={dataSource} columns={cells} pagination={false} loading={isLoading} rowKey="key"/>
            </Col>
        </Row>
        <ChatGptFormModal isOpened={chatGtpContextModalOpened} onSubmit={onChatGptSubmit} onCancel={() => setChatGptContextOpened(false)} currentValue={chatGptContext} />
    </>
}
