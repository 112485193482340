import React, { useState } from 'react';
import { Button, Input, message, Popconfirm, Typography } from 'antd';
import { DataSource } from '../logic/antTableDataSource';
import { askOpenAiPrompt } from '../api/TranslationApi';
import { SupportedCodes } from '../logic/supportedLanguages';
import { FormattedMessage, useIntl } from 'react-intl';

type EditableValueProps = {
    text: string,
    record: any,
    chatGptContext: string,
    keyToUpdate?: string,
    handleSave: (record: DataSource) => void
};

const { Text } = Typography;
export function ValueEditable({
                                  text,
                                  record,
                                  handleSave,
                                  chatGptContext,
                                  keyToUpdate = 'value'
                              }: EditableValueProps) {
    const intl = useIntl();
    const [openAiWaiting, setOpenAiWaiting] = useState(false);
    const [openAiPropmt, setOpenAiPrompt] = useState<string | null>(null);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const updateValue = (newText: string) => {
        handleSave({ ...record, [keyToUpdate]: newText })
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateValue(e.target.value)
    };

    const handleOpenAiCall = async () => {
        setOpenAiWaiting(true);
        try {
            const response = await askOpenAiPrompt({
                message: record.value,
                context: chatGptContext,
                sourceLang: 'en',
                targetLang: keyToUpdate.split('_')[0] as SupportedCodes
            });
            setOpenAiPrompt(response.translation);
            setConfirmOpen(true);
        } catch (e) {
            console.log(e);
            message.error(intl.formatMessage({ id: 'resourceEditing.valueLine.openAi.responseError' }));
        } finally {
            setOpenAiWaiting(false);
        }
    };

    const enterButton = <Button onClick={handleOpenAiCall} loading={openAiWaiting}><FormattedMessage id={'resourceEditing.valueLine.openAi.suggestButton'} /></Button>;

    const confirmPrompt = () => {
        if (openAiPropmt) {
            updateValue(openAiPropmt);
        }

        setConfirmOpen(false);
    };

    const handlePromptCancel = () => {
        setOpenAiPrompt(null);
        setConfirmOpen(false);
    };

    const popoverDescription = <>
        <p><Text type={'danger'} style={{ textDecoration: 'line-through'}}>{record[keyToUpdate]}</Text></p>
        <p><Text type={'success'}>{openAiPropmt}</Text></p>
    </>;

    return (
        <>
            {keyToUpdate === 'value' ?
                <Input
                    value={record[keyToUpdate]}
                    id={keyToUpdate}
                    type="text"
                    onChange={handleChange}
                /> :
                <Popconfirm title={intl.formatMessage({ id: 'resourceEditing.valueLine.openAi.confirmTitle' })}
                            okText={intl.formatMessage({ id: 'resourceEditing.valueLine.openAi.confirmOk' })}
                            cancelText={intl.formatMessage({ id: 'resourceEditing.valueLine.openAi.confirmCancel' })}
                            onConfirm={confirmPrompt}
                            onCancel={handlePromptCancel}
                            description={popoverDescription}
                            open={confirmOpen}>
                    <Input.Search
                        value={record[keyToUpdate]}
                        id={keyToUpdate}
                        type="text"
                        onChange={handleChange}
                        enterButton={enterButton}
                    />
                </Popconfirm>
            }
        </>
    );
}
