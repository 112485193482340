import React from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '../react-router-wrappers/Link';
import { ApiOutlined, MessageOutlined, SearchOutlined, SyncOutlined, TranslationOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import octopuses from '../assets/Octopuses are translating, realistic art.png'
import bears from '../assets/Two bears are translating.png'
import { CallToActionButton } from './CallToActionButton';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

export const LandingPage: React.FC = () => {
    const intl = useIntl();
    return (
        <Layout>
            <Helmet>
                <title>Locmat</title>
                <meta name="keywords" content={intl.formatMessage({ id: 'homePageNew.meta.keywords' })}/>
                <meta
                    name="description"
                    content={intl.formatMessage({ id: 'homePageNew.meta.description' })}
                />
            </Helmet>
            <Content className={'page-content'} style={{ background: '#fff', }}>
                <div style={{ padding: 24, minHeight: 380 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24}>
                            <Row>
                                <Col xs={24} sm={24} md={12}>
                                    <Title level={1}><FormattedMessage id={"homePageNew.slogan"}/></Title>
                                    <Paragraph style={{ fontSize: '1.6em', paddingTop: '50px' }}>
                                        <FormattedMessage id={"homePageNew.description"}/>
                                    </Paragraph>
                                </Col>
                                <Col xs={24} sm={24} md={10}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        alignItems: 'center',
                                        position: 'relative',
                                    }}>
                                        <div style={{ width: '250px', position: 'relative', opacity: 0.8 }}>
                                            <img width={250} src={bears} alt="Two bears are talking, art."/>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    color: 'black',
                                                    fontWeight: '500',
                                                    fontSize: '0.85em',
                                                    textAlign: 'center',
                                                    top: '3%',
                                                    left: '22%'
                                                }}
                                            >
                                                Do you speak portuguese? <br/>
                                                Você fala português?
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '20px' }}>
                                <Col xs={24} sm={24} md={10}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'center',
                                        position: 'relative',
                                    }}>
                                        <img width={250} src={octopuses}
                                             alt="Octopuses are translating, realistic art."/>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <Paragraph style={{ fontSize: '1.6em', paddingTop: '50px' }}>
                                        <FormattedMessage id={"homePageNew.description2"}/>
                                    </Paragraph>
                                </Col>
                            </Row>
                            <CallToActionButton>
                                <FormattedMessage id={'homePageNew.startNow'}/>
                            </CallToActionButton>
                        </Col>
                    </Row>
                    <Title level={2}><FormattedMessage id={'homePageNew.features.title'}/></Title>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={12}>
                            <TranslationOutlined style={{ fontSize: '48px' }}/>
                            <Title level={3}>
                                <FormattedMessage id={'homePageNew.features.item1.heading'}/></Title>
                            <Paragraph>
                                <FormattedMessage id={'homePageNew.features.item1.description'}/>
                            </Paragraph>
                            <Link to={'/icu-syntax'}>
                                <FormattedMessage id={'homePageNew.features.item1.readMore'}/>
                            </Link>
                        </Col>
                        <Col xs={24} sm={12} md={12}>
                            <ApiOutlined style={{ fontSize: '48px' }}/>
                            <Title level={3}>
                                <FormattedMessage id={'homePageNew.features.item2.heading'}/></Title>
                            <Paragraph>
                                <FormattedMessage id={'homePageNew.features.item2.description'}/>
                            </Paragraph>
                        </Col>
                        <Col xs={24} sm={12} md={12}>
                            <SyncOutlined style={{ fontSize: '48px' }}/>
                            <Title level={3}>
                                <FormattedMessage id={'homePageNew.features.item3.heading'}/></Title>
                            <Paragraph>
                                <FormattedMessage id={'homePageNew.features.item3.description'}/>
                            </Paragraph>
                            <Link to={'/machine-auto-translation'}>
                                <FormattedMessage id={'homePageNew.features.item3.readMore'}/>
                            </Link>
                        </Col>
                        <Col xs={24} sm={12} md={12}>
                            <MessageOutlined style={{ fontSize: '48px' }}/>
                            <Title level={3}>
                                <FormattedMessage id={'homePageNew.features.item4.heading'}/></Title>
                            <Paragraph>
                                <FormattedMessage id={'homePageNew.features.item4.description'}/>
                            </Paragraph>
                            <Link to={'/chat-gpt-integration'}>
                                <FormattedMessage id={'homePageNew.features.item4.readMore'}/>
                            </Link>
                        </Col>
                        <Col xs={24} sm={12} md={12}>
                            <SearchOutlined style={{ fontSize: '48px' }}/>
                            <Title level={3}>
                                <FormattedMessage id={'homePageNew.features.item5.heading'}/>
                            </Title>
                            <Paragraph>
                                <FormattedMessage id={'homePageNew.features.item5.description'} values={{
                                    a: chunks => <a href="mailto:support@locmat.app">{chunks}</a>
                                }}/>
                            </Paragraph>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout>
    );
};
