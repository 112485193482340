import { SupportedCodes } from './logic/supportedLanguages';
import { authInfo } from './api/AuthHolder';
import { Logo } from './Logo';
import { Button, Drawer, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { LanguagesDropdown } from './ResourceEditing/LanguagesDropdown';
import { Link } from './react-router-wrappers/Link';
import { useMediaQuery } from '@react-hook/media-query';
import { MenuOutlined } from '@ant-design/icons';

export function HeaderContent(props: {
    selectedLang: SupportedCodes;
}) {
    const authData = authInfo.getAuthInfo();
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    const logout = () => {
        authInfo.accessToken = null;
        window.location.href = '/auth/login'
    }

    const bigScreen = useMediaQuery('only screen and (min-width: 800px)')
    const navigationElement = <><>
        <div className={'auth-info'}>
            <Space direction={bigScreen ? 'horizontal' : 'vertical'}>
                {authData != null
                    ? <span className={'login-display'}>{authData.displayName}</span>
                    : <Link to={'/auth/login'}><Button><FormattedMessage id={'header.login'}/></Button></Link>}
                {authData && <Button onClick={logout} type={bigScreen ? 'default' : 'link'}><FormattedMessage
					id={'header.logout'}/></Button>}
            </Space>
        </div>
        <div className={'language-selector'}>
            <Space direction={bigScreen ? 'horizontal' : 'vertical'}>
                <LanguagesDropdown selectedLang={props.selectedLang}/>
            </Space>
        </div>
    </>
    </>;
    return <>
        <div className={'logo'}>
            <Link to="/">
                <Logo size={bigScreen ? 'full' : 'small'}/>
            </Link>
        </div>
        {bigScreen ?
            navigationElement : (<div className={'burger-button'}>
                <Button type="ghost" onClick={showDrawer}>
                    <MenuOutlined/>
                </Button>
                <Drawer
                    title="Menu"
                    placement="right"
                    closable={true}
                    onClose={onClose}
                    open={visible}
                >
                    <div className="nav-in-drawer">
                        {navigationElement}
                    </div>
                </Drawer>
            </div>)
        }
    </>;
}
