import { useNavigate as originalUseNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';

export const useNavigate = () => {
    const navigationFunc = originalUseNavigate();
    const { lang } = useParams();
    const wrapper: ReturnType<typeof originalUseNavigate> = useCallback((...args: any[]) => {
        const newArgs = [...args];
        if (typeof args[0] === 'string' && lang) {
            newArgs[0] = `/${lang}${args[0]}`
        }

        // @ts-ignore
        return navigationFunc.apply(null, newArgs);
    }, [lang, navigationFunc]);

    return wrapper;
}
