import React from 'react';
import { Typography, List } from 'antd';

const { Title, Paragraph } = Typography;

const WhyTMSBenefits = [
    'Centralized Translation Workflow: A TMS provides a single, centralized platform where all translation-related tasks can be managed. This eliminates the need for manual coordination and facilitates better collaboration among project stakeholders, including translators, reviewers, and project managers.',
    'Automation and Efficiency: TMS platforms automate many time-consuming tasks, such as content extraction, translation memory management, and file format conversions. This automation significantly reduces manual effort, accelerates the translation process, and allows for faster time-to-market.',
    'Enhanced Consistency and Quality: TMS platforms enable the creation and management of translation memories and glossaries. Translation memories store previously translated segments, allowing for reuse and ensuring consistent terminology across projects. Glossaries maintain a centralized repository of industry-specific terms and phrases, ensuring accurate translations and maintaining brand consistency.',
    'Streamlined Project Management: TMS platforms offer project management features, including progress tracking, deadline management, and resource allocation. These tools enable efficient project planning, resource optimization, and better control over localization workflows.',
    'Cost Savings: By automating various aspects of the translation process and improving efficiency, TMS platforms help reduce costs associated with manual translation management. Additionally, the reuse of translated content through translation memories leads to significant savings, especially for technology companies with large volumes of repetitive content.',
];

const HowTMSBenefits = [
    'Faster International Expansion: TMS platforms allow technology companies to rapidly localize their products and services for global markets. By accelerating the translation process, companies can quickly enter new markets, gain a competitive edge, and increase their global presence.',
    'Improved Localization Quality: The consistency and accuracy achieved through TMS platforms enhance the quality of localized content. This is crucial for technology companies, as accurate and culturally appropriate translations contribute to a positive user experience, higher customer satisfaction, and increased trust in their brand.',
    'Scalability and Flexibility: TMS platforms can handle large volumes of content and support multiple languages simultaneously. This scalability enables technology companies to manage localization for multiple projects and platforms, ensuring seamless expansion without compromising quality.',
    'Collaboration and Communication: TMS platforms facilitate effective collaboration and communication among project stakeholders. Translators, reviewers, and project managers can work together within the TMS, sharing feedback, discussing terminology, and addressing any queries in real-time. This streamlines the localization process and improves overall project coordination.',
    'Integration with Existing Systems: Translation Management Systems can integrate with other software systems, such as content management systems (CMS) or e-commerce platforms. This integration enables seamless content transfer, automates content updates, and ensures that translated content is synchronized with the latest source material.',
];

export const IntroductionToTms: React.FC = () => {
    return <div style={{ padding: '1em' }}>
        <Title>An Introduction to Translation Management Systems: Boosting Efficiency in Localization</Title>

        <Title level={2}>Introduction</Title>
        <Paragraph>
            In today's interconnected world, businesses strive to expand their global reach, making localization a crucial aspect of their operations. Localization involves adapting content, such as websites and software applications, to suit the linguistic and cultural preferences of target audiences. In the past, managing translations for websites and other digital content was a manual and time-consuming process. However, the advent of Translation Management Systems (TMS) revolutionized the way companies handle localization, significantly boosting efficiency and accuracy. This article provides an overview of TMS, its significance, and the benefits it offers to technology companies.
        </Paragraph>

        <Title level={2}>Background</Title>
        <Paragraph>
            Traditional Website Translation Methods Before the emergence of Translation Management Systems, website translations were often managed through manual processes. This typically involved the use of spreadsheets or documents to keep track of translations, multiple email exchanges with translators, and manual copying and pasting of translated content onto the website. This approach was not only laborious but also prone to errors, delays, and inconsistencies. As the demand for multilingual websites grew, technology companies realized the need for a more streamlined and automated solution.
        </Paragraph>

        <Title level={2}>What is a Translation Management System (TMS)?</Title>
        <Paragraph>
            A Translation Management System is a software platform designed to streamline and automate the translation process. It provides a centralized hub for managing all aspects of localization, from content submission to translation, review, and final implementation. TMS platforms offer a range of features that simplify and accelerate the translation workflow, ensuring greater efficiency, consistency, and quality in the localization process.
        </Paragraph>

        <Title level={2}>Why is a Translation Management System Needed?</Title>
        <List
            bordered
            dataSource={WhyTMSBenefits}
            renderItem={item => (
                <List.Item>
                    <Paragraph>{item}</Paragraph>
                </List.Item>
            )}
        />

        <Title level={2}>How Translation Management Systems Benefit Technology Companies</Title>
        <List
            bordered
            dataSource={HowTMSBenefits}
            renderItem={item => (
                <List.Item>
                    <Paragraph>{item}</Paragraph>
                </List.Item>
            )}
        />

        <Title level={2}>Conclusion</Title>
        <Paragraph>
            Translation Management Systems have revolutionized the way technology companies handle localization, offering a centralized and efficient solution for managing translations. By automating manual processes, ensuring consistency, and improving collaboration, TMS platforms boost efficiency and enhance the quality of localized content. For technology companies aiming to expand globally and reach diverse audiences, implementing a Translation Management System is essential for streamlining the translation workflow, reducing costs, and maintaining brand integrity in the global marketplace.
        </Paragraph>
    </div>;
}
