import { SupportedCodes } from './supportedLanguages';

type DataSourceRecord = { key: string, value: string, [key: string]: string };

function fillByKey(to: any, key: string, value: string) {
    let curr = to;
    const path = key.split('.')
    for (let i = 0; i < path.length - 1; i++) {
        const key = path[i];
        if (key === '__proto__') throw new Error(`${key} is not valid`)
        if (typeof curr !== 'object' || curr === null || key in curr === false) {
            curr[key] = {};
        }

        curr = curr[key];
    }
    curr[path[path.length - 1]] = value;
}

export const getResourcesFromDataSource = (dataSource: DataSourceRecord[], lang: SupportedCodes) => {
    const result = {} as any;
    for (let i = 0; i < dataSource.length; i++) {
        const value = dataSource[i][lang === 'en' ? 'value' : `${lang}_value`];
        fillByKey(result, dataSource[i].key, value);
    }

    return result;
}
