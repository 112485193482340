import { SupportedCodes } from '../logic/supportedLanguages';
import { authInfo } from './AuthHolder';
import { apiConfig } from './apiConfig';

export async function translateResources(resources: any, targetLang: SupportedCodes) {
    const response = await fetch(`${(apiConfig.baseUrl)}/api/v1/translation`, {
        method: 'POST',
        headers: authInfo.withBearer({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            toLanguage: targetLang,
            resources
        })
    })

    // todo: errors
    return await response.json();
}

export async function echo() {
    const response = await fetch(`${(apiConfig.baseUrl)}/api/v1/echo`, {
        method: 'GET',
        headers: authInfo.withBearer({
            'Content-Type': 'application/json',
        })
    })

    // todo: errors
    return await response.json();
}

export async function getProjects() {
    const response = await fetch(`${(apiConfig.baseUrl)}/api/v1/projects`, {
        method: 'GET',
        headers: authInfo.withBearer({
            'Content-Type': 'application/json',
        })
    })

    // todo: errors
    return await response.json();
}


export async function saveProject(project: any) {
    const response = await fetch(`${(apiConfig.baseUrl)}/api/v1/projects`, {
        method: 'POST',
        headers: authInfo.withBearer({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(project)
    })

    if (!response.ok) {
        throw new Error('Failed to save project: ' + response.statusText)
    }

    return await response.json();
}


export async function getProjectById(projectId: string) {
    const response = await fetch(`${(apiConfig.baseUrl)}/api/v1/projects/${projectId}`, {
        method: 'GET',
        headers: authInfo.withBearer({
            'Content-Type': 'application/json',
        })
    })

    // todo: errors
    return await response.json();
}

export async function saveProjectResources(project: any, lang: string, resources: any) {
    const response = await fetch(`${(apiConfig.baseUrl)}/api/v1/projects/${project.id}/resources`, {
        method: 'POST',
        headers: authInfo.withBearer({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            lang,
            resources
        })
    })

    if (!response.ok) {
        throw new Error('Failed to save project resources: ' + response.statusText);
    }

    return await response.json();
}


export interface ApiKey {
    id: string;
    name: string;
    dateGenerated: string;
}

export const fetchApiKeys = async (): Promise<ApiKey[]> => {
    const response = await fetch(`${(apiConfig.baseUrl)}/api/v1/api-keys`, {
        method: 'GET',
        headers: authInfo.withBearer({
            'Content-Type': 'application/json',
        })
    });
    const data = await response.json();
    return data.apiKeys;
};

export interface CreateApiKeyParams {
    name: string;
}

export const createApiKey = async (params: CreateApiKeyParams): Promise<ApiKey> => {
    const response = await fetch(`${apiConfig.baseUrl}/api/v1/api-keys`, {
        method: 'POST',
        headers: authInfo.withBearer({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(params),
    });

    if (!response.ok) {
        throw new Error('Failed to create API Key ' + response.statusText);
    }

    return await response.json();
};

export const deleteApiKey = async (id: string): Promise<void> => {
    const response = await fetch(`${apiConfig.baseUrl}/api/v1/api-keys/${id}`,{ method: 'DELETE', headers:  authInfo.withBearer({}) });
    if (!response.ok) {
        throw new Error('Unable to delete key ' + response.statusText)
    }
};

interface OpenAiPromtRequest {
    targetLang: SupportedCodes;
    sourceLang: SupportedCodes;
    /**
     * User defined context that will be used as additional input to chat-gpt model
     */
    context?: string;
    message: string;
}

interface OpenAiPromptResponse {
    translation: string;
}

export const askOpenAiPrompt = async (params: OpenAiPromtRequest): Promise<OpenAiPromptResponse> => {
    const response = await fetch(`${apiConfig.baseUrl}/api/v1/openAi/prompt`, {
        method: 'POST',
        headers: authInfo.withBearer({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(params),
    });

    if (!response.ok) {
        throw new Error('Failed to ask OpenAI' + response.statusText);
    }

    return await response.json();
};

