const sampleResources = {
    "greeting": "Hey there! How's it hangin'?",
    "introduction": "Allow me to introduce myself. My name is Inigo Montoya. You killed my father. Prepare to die.",
    "compliment": "You're looking sharp today. Did you change your oil or something?",
    "joke": "Why did the tomato turn red? Because it saw the salad dressing!",
    "sarcasm": "Oh great, another meeting. I was really hoping to spend more time sitting and staring at my computer screen.",
    "insult": "You must have been born on a highway, because that's where most accidents happen.",
    "philosophy": "Why do they call it rush hour when nothing moves?",
    "goodbye": "See ya later, alligator! In a while, crocodile!",
    "meme": "When you realize it's Monday tomorrow"
};

export { sampleResources };
