import { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Space, Alert, Typography } from 'antd';
import { createApiKey, deleteApiKey, fetchApiKeys } from '../api/TranslationApi';
import { ColumnsType } from 'antd/es/table';
import { FormattedDate, useIntl } from 'react-intl';
const { Title } = Typography;

interface ApiKey {
    apiKey?: string;
    id: string;
    name: string;
    dateGenerated: string;
}

interface ApiKeyFormValues {
    name: string;
}

export const ApiKeys = () => {
    const intl = useIntl();
    const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
    const [apiKeysLoading, setApiKeysLoading] = useState<boolean>(false);
    const [modalState, setModalState] = useState<'hidden' | 'newKey' | 'showApiKey'>('hidden');
    const [form] = Form.useForm();

    useEffect(() => {
        const loadApiKeys = async () => {
            setApiKeysLoading(true)
            try {
                const result = await fetchApiKeys();
                setApiKeys(result);
            } finally {
                setApiKeysLoading(false);
            }
        };
        loadApiKeys();
    }, []);

    const handleCreateApiKey = async (values: ApiKeyFormValues) => {
        try {
            const createdKey = await createApiKey({ name: values.name });
            setApiKeys([...apiKeys, createdKey]);
            message.success('API key created successfully');
            setModalState('showApiKey');
            form.resetFields();
        } catch (error) {
            console.error(error);
            message.error('Failed to create API key');
        }
    };

    const handleDeleteApiKey = async (apiKey: ApiKey) => {
        try {
            await deleteApiKey(apiKey.id);
            setApiKeys(apiKeys.filter((item) => item.id !== apiKey.id));
            message.success('API key deleted successfully');
        } catch (error) {
            console.error(error);
            message.error('Failed to delete API key');
        }
    };

    const columns: ColumnsType<ApiKey> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Date Generated',
            dataIndex: 'dateGenerated',
            key: 'dateGenerated',
            render: (value) => {
                return <FormattedDate value={value} dateStyle={'medium'} timeStyle={'medium'}/>
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_: any, record: ApiKey) => (
                <Button danger onClick={() => handleDeleteApiKey(record)}>
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <div>
            <Space style={{ padding: '20px 0' }}>
                <Button type="primary" onClick={() => setModalState('newKey')}>
                    Create API Key
                </Button>
            </Space>
            <Table<ApiKey> dataSource={apiKeys} columns={columns} rowKey="id" loading={apiKeysLoading}
                           pagination={false}/>
            <Modal
                title="Create API Key"
                open={modalState !== 'hidden'}
                onOk={modalState === 'newKey' ? form.submit : () => setModalState('hidden')}
                onCancel={() => setModalState('hidden')}
                okText={modalState === 'newKey' ? "Create" : "Ok, I saved!"}
                cancelText="Cancel"
            >
                {modalState === 'newKey' ?
                    (<Form form={form} onFinish={handleCreateApiKey}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a name for the API key',
                                },
                            ]}
                        >
                            <Input autoFocus={true}/>
                        </Form.Item>
                    </Form>) : null
                }
                { modalState === 'showApiKey' ? (
                    <>
                        <Alert message={''} showIcon type={'warning'} description={intl.formatMessage({ id: 'apiKeys.saveKey' })}/>
                        <Title level={4} copyable={true} style={{ textAlign: 'center', padding: '20px 0' }}>
                            {apiKeys[apiKeys.length -1].apiKey}
                        </Title>
                    </>
                ): null}
            </Modal>
        </div>
    );
};
