export type DataSource = { key: string, value: string, [key: string]: string };

export function fillDataSource(resource: any, dataSource: DataSource[], prefix = '') {
    for (const key in resource) {
        if (typeof resource[key] === 'string') {
            const combinedKey = `${prefix}${key}`;
            const existing = dataSource.find(i => i.key === combinedKey);
            if (existing) {
                existing.value = resource[key]
            } else {
                dataSource.push({ key: combinedKey, value: resource[key] })
            }
        }

        if (typeof resource[key] === 'object') {
            // todo handle infinitive recursion
            fillDataSource(resource[key], dataSource, `${prefix}${key}.`)
        }
    }
}

export function fillDataSourceTranslations(dataSource: DataSource[], targetLang: string, translations: any, prefix = '') {
    for (const key in translations) {
        if (typeof translations[key] === 'string') {
            const dataSourceValue = dataSource.find(s => s.key === `${prefix}${key}`);
            if (dataSourceValue) {
                dataSourceValue[`${targetLang}_value`] = translations[key];
            } else {
                console.warn(`No datasource value found for key ${key}`);
            }
        } else {
            fillDataSourceTranslations(dataSource, targetLang, translations[key], `${prefix}${key}.`)
        }
    }
}
