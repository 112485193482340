import React from 'react';
import { Typography, List } from 'antd';

const { Title, Paragraph } = Typography;

const TMSAndAutomatedTranslation = [
    'Content Submission and Integration: TMS platforms allow users to submit content for translation, either manually or through automated content extraction. The extracted content is then sent to the automated translation service for processing. TMS systems often support integration with various automated translation engines, enabling seamless connectivity between the TMS and the chosen translation service.',
    'Translation Workflow Management: Once the content is sent for translation, TMS platforms manage the translation workflow. This includes tracking the progress of translations, assigning tasks to translators or automated translation engines, and managing deadlines. TMS systems offer a collaborative environment where project stakeholders, including human translators, reviewers, and automated translation services, can work together efficiently.',
    'Post-Editing and Quality Assurance: Automated translations are not always perfect and may require human intervention to ensure accuracy and linguistic fluency. TMS platforms facilitate post-editing, where human translators or reviewers can fine-tune and refine the machine-generated translations. TMS systems provide tools and features for seamless post-editing and quality assurance, ensuring the final translated content meets the desired standards.',
    'Translation Memory and Terminology Management: TMS platforms incorporate translation memory and terminology management functionalities, which can be utilized with automated translation services. Translation memory stores previously translated segments, enabling the reuse of approved translations for consistent terminology and improved efficiency. TMS systems also manage glossaries and terminology databases, ensuring accurate translations by automated services and human translators alike.',
];

const TMSBenefits = [
    'Speed and Efficiency: Automated translation services provide fast translations, allowing for quicker turnaround times, especially for large volumes of content. When combined with TMS platforms, the entire translation process becomes streamlined and efficient.',
    'Cost-Effectiveness: Automated translation services can significantly reduce translation costs, especially for repetitive or lower-priority content. TMS platforms enable the optimization of resources and the efficient allocation of tasks between automated translation services and human translators, helping businesses save on translation expenses.',
    'Scalability: Automated translation services integrated with TMS platforms enable businesses to handle a higher volume of translation projects. As the demand for translations grows, the combination of automation and centralized management ensures scalability without compromising quality.',
    'Consistency and Quality Control: TMS platforms ensure consistency in translations by leveraging translation memory and terminology management features. When integrated with automated translation services, TMS systems maintain linguistic consistency across projects and improve overall translation quality.',
    'Collaboration and Workflow Management: TMS platforms provide a collaborative environment where human translators, reviewers, and automated translation services can work together seamlessly. The integrated workflow management tools within TMS platforms streamline project coordination and enhance communication among project stakeholders.',
];

export const BeginnerGuideToTms: React.FC = () => {
    return (
        <div style={{ padding: '1em' }}>
            <Title>A Beginner's Guide to Automated Translation Services</Title>

            <Title level={2}>Introduction</Title>
            <Paragraph>
                In today's globalized world, the need for efficient and accurate translation services is paramount. Businesses and individuals alike require translations for various purposes, such as websites, documents, and communication with international clients. Automated Translation Services have emerged as a valuable tool to streamline the translation process, offering quick and cost-effective solutions. This article serves as a beginner's guide to understanding automated translation services, their benefits, and how they are connected to Translation Management Systems (TMS).
            </Paragraph>

            <Title level={2}>Understanding Automated Translation Services</Title>
            <Paragraph>
                Automated Translation Services, also known as Machine Translation (MT), utilize advanced algorithms and artificial intelligence to automatically translate text from one language to another. Unlike traditional human translation, which relies on manual effort, automated translation services leverage technology to analyze and process vast amounts of linguistic data.
            </Paragraph>

            <Paragraph>
                Translation Management Systems (TMS) in their turn play a crucial role in integrating automated translation services into the translation workflow. TMS platforms act as a centralized hub for managing and coordinating translation projects, including the use of automated translation.
            </Paragraph>

            <Title level={2}>How Automated Translation Services are Connected to TMS</Title>
            <List
                bordered
                dataSource={TMSAndAutomatedTranslation}
                renderItem={item => (
                    <List.Item>
                        <Paragraph>{item}</Paragraph>
                    </List.Item>
                )}
            />

            <Title level={2}>Benefits of Automated Translation Services with TMS</Title>
            <List
                bordered
                dataSource={TMSBenefits}
                renderItem={item => (
                    <List.Item>
                        <Paragraph>{item}</Paragraph>
                    </List.Item>
                )}
            />

            <Title level={2}>Conclusion</Title>
            <Paragraph>
                Automated Translation Services, powered by advanced algorithms and artificial intelligence, have become valuable assets in the world of translation. When connected with Translation Management Systems, automated translation services offer a range of benefits, including speed, cost-effectiveness, scalability, and improved translation quality. The integration of automated translation services within TMS platforms enables businesses to efficiently manage translation projects, collaborate with human translators, and maintain consistency in their multilingual content. By leveraging automated translation services alongside TMS, businesses can effectively bridge language barriers and expand their global reach.
            </Paragraph>
        </div>
    );
}
