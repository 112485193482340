import React, { useState, useEffect } from 'react';
import { List, Button, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { getProjects, saveProject } from '../api/TranslationApi';
import { Link } from '../react-router-wrappers/Link';
import { FormattedMessage } from 'react-intl';

interface Project {
    id: string;
    name: string;
}

const ProjectsPage = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProjects = async () => {
            setLoading(true);
            try {
                const response = await getProjects();
                setProjects(response.items);
            } finally {
                setLoading(false);
            }
        };
        fetchProjects();
    }, []);

    const onFinish = async (values: { name: string }) => {
        setLoading(true);
        try {
            const response = await saveProject({ name: values.name });
            setProjects([...projects, response]);
        } finally {
            setLoading(false);
            form.resetFields();
        }
    };

    return (
        <>
            <List
                loading={loading}
                dataSource={projects}
                renderItem={(project) => (
                    <List.Item>
                        <Link to={`/app/projects/${project.id}`}>{project.name}</Link>
                    </List.Item>
                )}
            />
            <Form form={form} onFinish={onFinish} style={{ marginTop: 16 }}>
                <Form.Item name="name" rules={[{ required: true, message: 'Please enter a name' }]}>
                    <Input placeholder="Project name"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<PlusOutlined/>} loading={loading}>
                        <FormattedMessage id={'projects.addProject'} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default ProjectsPage;
