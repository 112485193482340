import React from 'react';
import { Form, Input, Modal } from 'antd';
import { useIntl } from 'react-intl';

interface ChatGptFormProps {
    isOpened: boolean;
    currentValue: string;
    onSubmit: (chatGptContext: string) => void;
    onCancel: () => void;
}

export const ChatGptFormModal: React.FC<ChatGptFormProps> = ({ onSubmit, isOpened, onCancel, currentValue }) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    form.setFieldsValue({ chatGptContext: currentValue });
    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                onSubmit(values.chatGptContext);
                form.resetFields();
            })
            .catch((error) => {
                console.error('Validation failed:', error);
            });
    };

    return (
        <>
            <Modal
                title="Enter ChatGPT Context"
                open={isOpened}
                onOk={handleOk}
                onCancel={onCancel}
                forceRender
            >
                <Form form={form} layout="vertical" onSubmitCapture={handleOk} name="chatGptContext_form">
                    <Form.Item
                        name="chatGptContext"
                        label={intl.formatMessage({ id: 'resourceEditing.chatGptContext.inputName' })}
                    >
                        <Input maxLength={120} autoFocus={true}
                               placeholder={intl.formatMessage({ id: 'resourceEditing.chatGptContext.placeholder' })}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
