import jwtDecode from 'jwt-decode';

const accessTokenIdx = 'access_token';

export class AuthHolder {
    get accessToken(): string | null {
        return localStorage.getItem(accessTokenIdx);
    }

    set accessToken(value: string | null) {
        if (value === null) {
            localStorage.removeItem(accessTokenIdx);
            return;
        }

        localStorage.setItem(accessTokenIdx, value);
    }

    getAuthInfo() {
        const token = this.accessToken;
        if (token == null) {
            return null;
        }

        return jwtDecode(token) as { displayName: string };
    }

    withBearer(headers: any): any {
        return {
            ...headers,
            'Authorization': `Bearer ${this.accessToken}`,
        }
    }
}

export const authInfo = new AuthHolder();
