import React from 'react';
import { Button, Card, Layout, Typography } from 'antd';
import { BlogPost, blogPosts } from './BlogPosts';
import { Link } from 'react-router-dom';

const { Content } = Layout;
const { Title } = Typography;

export const BlogList: React.FC = () => {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={3} style={{ marginTop: '30px', marginBottom: '30px' }}>Locmat Blog</Title>
                {blogPosts.map((post: BlogPost) => (
                    <Card title={post.title} style={{ margin: '16px 0' }}>
                        <p>{post.summary}</p>
                        {/* there is no localization to blog :( */}
                        <Link to={post.url}>
                            <Button type="primary">Read More</Button>
                        </Link>
                    </Card>
                ))}
            </Content>
        </Layout>
    );
};
