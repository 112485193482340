import React from 'react';
import { Layout, Space } from 'antd';

const { Content } = Layout;

export const LandingLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <Content className={'page-content'} style={{ background: '#fff', }}>
            <div style={{ padding: '2rem', margin: 'auto', backgroundColor: 'white' }}>
                <Space direction={'vertical'} size={'large'}>
                    {children}
                </Space>
            </div>
        </Content>
    );
}
