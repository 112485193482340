export const supportedLanguages = [
    { code: 'en', displayName: 'English' },
    { code: 'pt-PT', displayName: 'Portuguese (Portugal)' },
    { code: 'uk', displayName: 'Ukrainian' },
    { code: 'es', displayName: 'Spanish' },
    { code: 'es-MX', displayName: 'Spanish (Mexico)' },
] as const;

export const codes = supportedLanguages.map(x => x.code);

export type SupportedCodes = typeof codes[0];
