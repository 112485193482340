import React from 'react';
import { Typography, List } from 'antd';

const { Title, Paragraph } = Typography;

const FormatOverview = [
    'XLIFF (XML Localization Interchange File Format): XLIFF is an XML-based format specifically designed for the localization of software, websites, and other digital content. It separates source content from its translations, making it easier to manage and exchange translation projects. XLIFF files store translatable segments, preserve formatting and metadata, and support collaboration between translators and translation tools. While XLIFF is commonly used in the localization industry, it may present limitations when used for website translations due to its lack of web-specific adaptations and the need for specialized XLIFF parsers.',
    'ICU (International Components for Unicode): ICU is not a format itself but a comprehensive library that provides robust support for Unicode and internationalization-related tasks. ICU offers a wide range of functionality for handling multilingual content and implementing internationalization features in software applications. Its features include Unicode support, locale management, collation and sorting, message formatting, transliteration, and more. ICU\'s versatility and flexibility make it highly convenient for translations, especially in web development scenarios.',
];

const FormatComparison = [
    'Structure and Purpose: XLIFF is specifically designed for localization purposes, focusing on storing and exchanging translation-related data. It is well-suited for managing and processing translations within the localization workflow. ICU, on the other hand, is a comprehensive library that provides broad support for Unicode and internationalization tasks beyond translation alone.',
    'Focus on Translation Workflow: XLIFF excels in managing the translation workflow, allowing for efficient collaboration between translators and translation tools. It supports context preservation, segmented translations, and metadata handling. ICU, although not primarily focused on the translation workflow, provides fundamental features such as message formatting and locale management that are crucial for generating accurate translations.',
    'Web Development Adaptability: ICU is widely adopted in web development due to its high flexibility and ease of use. It offers a comprehensive set of tools and features that align with the requirements of web-based translation projects. In contrast, XLIFF, while popular in the localization industry, may present challenges for website translations as it requires specific XLIFF parsers and lacks widespread adoption as a resource for websites.',
    'Scope and Functionality: XLIFF focuses primarily on localization projects, providing specialized support for storing and exchanging translation-related data. On the other hand, ICU, with its broader scope, covers a wide array of internationalization tasks beyond translation, including locale management, collation, sorting, and message formatting. This makes ICU more versatile and adaptable to various aspects of multilingual content handling.',
];

export const OverviewOfFormats: React.FC = () => {
    return (
        <div style={{ padding: '1em' }}>
            <Title>An Overview of Popular Translation Formats: Comparing XLIFF and ICU</Title>

            <Title level={2}>Introduction</Title>
            <Paragraph>
                Translation formats play a vital role in the localization industry, enabling efficient and accurate translation processes. Among the popular formats, XLIFF (XML Localization Interchange File Format) and ICU (International Components for Unicode) stand out as widely adopted choices. This article provides an overview of these formats, compares their features, and concludes why ICU is particularly well-suited for translations, especially in web development scenarios.
            </Paragraph>

            <Title level={2}>Overview of XLIFF and ICU Formats</Title>
            <List
                bordered
                dataSource={FormatOverview}
                renderItem={item => (
                    <List.Item>
                        <Paragraph>{item}</Paragraph>
                    </List.Item>
                )}
            />

            <Title level={2}>Comparison of XLIFF and ICU</Title>
            <List
                bordered
                dataSource={FormatComparison}
                renderItem={item => (
                    <List.Item>
                        <Paragraph>{item}</Paragraph>
                    </List.Item>
                )}
            />

            <Title level={2}>Conclusion</Title>
            <Paragraph>
                ICU's Strength in Translations for Web Development ICU emerges as an excellent format for translations, particularly in web development scenarios, due to its wide adoption and convenience. Its flexibility, versatility, and comprehensive feature set make it an ideal choice for handling multilingual content. ICU's extensive support for Unicode, locale management, message formatting, and other internationalization capabilities are highly valuable in achieving accurate and culturally appropriate translations.
                While XLIFF remains popular in the localization industry, its application to website translations can be challenging. The need for specialized XLIFF parsers and its limited adoption as a web resource hinder its effectiveness in web development projects. In contrast, ICU's widespread use, flexibility, and ease of integration with web development frameworks make it a preferred format for translations, ensuring efficient localization processes and maintaining language consistency across web-based applications.
                ICU's wide adoption, high flexibility, and ease of use in web development make it an exceptional choice for translations. Its comprehensive support for multilingual content handling and internationalization tasks contributes to accurate and efficient translations, addressing the specific requirements of web-based projects. While XLIFF serves its purpose in the localization industry, ICU stands out as the preferred format for web development, ensuring seamless translation integration and enabling developers to deliver exceptional multilingual experiences.
            </Paragraph>
        </div>
    );
}
