import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SupportedCodes, supportedLanguages } from './logic/supportedLanguages';

const appUrl = process.env.REACT_APP_BASE_URI || 'https://locmat.app'

const defaultLang = 'en';

const generateLinkTags = (currentPathname: string) => {
    let path = currentPathname;
    const currPathSplits = currentPathname.split('/');
    if (currPathSplits.length > 1 && supportedLanguages.some(l => l.code === currPathSplits[1])) {
        path = '/' + currPathSplits.slice(2).join('/');
    }

    // Generate <link> tags for all available languages
    return supportedLanguages.map((lang) => {
        const injectLang = lang.code === defaultLang ? '' : lang.code;
        const newPath = `/${injectLang + path}`.replace('//', '/');  // very bad :(
        const url = `${appUrl}${newPath}`;
        return (
            <link
                key={lang.code}
                rel="alternate"
                hrefLang={lang.code}
                href={url}
            />
        );
    });
};


function buildCanonical(pathname: string, lang: SupportedCodes | undefined) {
    if (lang !== defaultLang) {
        return null;
    }

    const path = pathname.replace(`/${defaultLang}`, '');

    return (
        <link rel={'canonical'} href={`${appUrl}${path}`}/>
    )
}

export const MetaAttrs = () => {
    const location = useLocation();
    const params = useParams();
    return (
        <Helmet>
            {generateLinkTags(location.pathname)}
            {buildCanonical(location.pathname, params.lang as SupportedCodes | undefined)}
        </Helmet>
    );
};
