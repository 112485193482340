import React from 'react';
import { Select } from 'antd';
import { SupportedCodes, supportedLanguages } from '../logic/supportedLanguages';

interface Props {
    onLangChange: (value: SupportedCodes) => void;
}

export const LanguagesSelect: React.FC<Props> = ({ onLangChange }) => {
    const handleChange = (value: SupportedCodes) => {
        onLangChange(value);
    };

    return (
        <Select
            defaultValue="en"
            style={{ width: 220 }}
            onChange={handleChange}
            options={supportedLanguages.map(lang => ({ value: lang.code, label: lang.displayName }))}
        />
    );
};


