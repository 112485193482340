import React from 'react';
import { Breadcrumb, Card, Layout, Row } from 'antd';
import { Link } from '../react-router-wrappers/Link';
import { useParams } from 'react-router-dom';
import { blogPosts } from './BlogPosts';
import { FormattedMessage } from 'react-intl';

const { Content } = Layout;
export const BlogArticle: React.FC = () => {
    const routeParams = useParams();
    const articleId = routeParams.articleId as string;
    const blogPost = blogPosts.find(bp => bp.url.endsWith(articleId));
    if (!blogPost) {
        return <div>Not found</div>
    }

    const BlogComponent = blogPost.component;
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Row style={{ marginTop: '30px' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/blog"><FormattedMessage id={'blog.blog'}/></Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{blogPost.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <Card>
                        <BlogComponent/>
                    </Card>
                </Row>
            </Content>
        </Layout>
    );
};
