import React from 'react';
import { SupportedCodes, supportedLanguages } from '../logic/supportedLanguages';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

type Props = { selectedLang: SupportedCodes };
export const LanguagesDropdown: React.FC<Props> = ({ selectedLang }) => {
    const location = useLocation();
    const { lang } = useParams();

    // Plenty of bugs, I know...
    const replaceLocation = (newLang: SupportedCodes) => {
        if (!lang) {
            return `/${newLang}${location.pathname}`;
        } else {
            return location.pathname.replace(`/${lang}`, `/${newLang}`)
        }
    }
    const items = supportedLanguages.map(lang => ({
        key: lang.code,
        label: (<Link to={replaceLocation(lang.code)}>{lang.displayName}</Link>)
    }));
    const selected = supportedLanguages.find(lang => lang.code === selectedLang);

    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <Space>
                <Button type={'link'}>
                    {selected!.displayName}
                    <DownOutlined/>
                </Button>
            </Space>
        </Dropdown>
    );
};
