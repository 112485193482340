import React from 'react';
import { Link } from '../react-router-wrappers/Link';
import { Button } from 'antd';

export function CallToActionButton({ children }: { children: React.ReactNode }) {
    return <Link to={'/auth/login'}>
        <Button block={true} size={'large'} style={{
            margin: '50px 0',
            color: 'white',
            fontWeight: '600',
            fontSize: '1.4em',
            height: '4rem',
            backgroundImage: 'linear-gradient(135deg, rgb(169, 37, 128), rgb(247, 188, 0) 75%)'
        }}>
            {children}
        </Button>
    </Link>;
}
