import React from 'react';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title, Text } = Typography;

export const PrivacyPolicy: React.FC = () => {
    return (
        <Layout>
            <Content style={{ padding: '50px' }}>
                <Title level={3} style={{ marginTop: '30px', marginBottom: '30px' }}>Privacy Policy</Title>
                <p>
                    <Text>
                        We understand the importance of privacy and are committed to protecting the privacy of our
                        users.
                        This privacy policy explains how we collect, use, and safeguard your personal information.
                    </Text>
                </p>
                <br/>
                <p>
                    <Text>
                        We do not collect any private information except for the information provided by 3rd party
                        providers,
                        for which explicit consent is asked before collection.
                    </Text>
                </p>
                <br/>
                <p>
                    <Text>
                        Please note that we do not sell, rent, or share your personal information with any third party
                        for
                        marketing purposes.
                    </Text>
                </p>
                <br/>
                <p>
                    <Text>
                        If you have any questions or concerns regarding our privacy policy, please contact us at <a
                        href="mailto:support@locmat.app">support@locmat.app</a>
                    </Text>
                </p>
            </Content>
        </Layout>
    );
};
