import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { LandingPage } from './LandingPage/LandingPage';
import { RequireAuth } from './RequireAuth';
import { ResourcesEditing } from './ResourceEditing/ResourcesEditing';
import ProjectsList from './projects/ProjectsList';
import { AuthLogin } from './AuthLogin';
import { AuthSuccess } from './AuthSuccess';
import { PrivacyPolicy } from './LandingPage/PrivacyPolicy';
import App from './App';
import { ApiKeys } from './ApiKeys/ApiKeys';
import { ICUSyntaxLanding } from './LandingPage/ICUSyntaxLanding';
import { ChatGPTIntegration } from './LandingPage/ChatGPTIntegration';
import { AutoTranslationLanding } from './LandingPage/AutoTranslationLanding';
import { BlogList } from './LandingPage/BlogList';
import { BlogArticle } from './LandingPage/BlogArticle';

const children = [
    {
        path: '',
        element: <LandingPage/>,
    },
    {
        path: 'icu-syntax',
        element: <ICUSyntaxLanding/>,
    },
    {
        path: 'chat-gpt-integration',
        element: <ChatGPTIntegration/>,
    },
    {
        path: 'machine-auto-translation',
        element: <AutoTranslationLanding/>,
    },
    {
        path: 'privacy-policy',
        element: <PrivacyPolicy/>,
    },
    {
        path: 'blog',
        element: <BlogList/>,
    },
    {
        path: 'blog/:articleId',
        element: <BlogArticle/>,
    },
    {
        path: 'app/projects/:projectId',
        element: <RequireAuth><ResourcesEditing/></RequireAuth>,
    },
    {
        path: 'app/projects',
        element: <RequireAuth><ProjectsList/></RequireAuth>,
    },
    {
        path: 'app/api-keys',
        element: <RequireAuth><ApiKeys/></RequireAuth>,
    },
    {
        path: 'auth/login',
        element: <AuthLogin/>
    },
    {
        path: 'auth/success',
        element: <AuthSuccess/>
    }
];
export const routes = createBrowserRouter([
    {
        path: '/:lang?/',
        element: <App />,
        children: children
    }
]);
