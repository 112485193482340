import React from 'react';
import { IntroductionToTms } from './blog/1-IntroductionToTms';
import { BeginnerGuideToTms } from './blog/2-BeginnerGuideToTms';
import { OverviewOfFormats } from './blog/3-OverviewOfFormats';

export interface BlogPost {
    title: string;
    summary: string;
    url: string;
    component: React.ComponentType;
}

export const blogPosts: BlogPost[] = [
    {
        title: 'An Introduction to Translation Management Systems: Boosting Efficiency in Localization',
        summary: 'In today\'s interconnected world, businesses strive to expand their global reach, making localization a crucial aspect of their operations. Localization involves adapting content, such as websites and software applications, to suit the linguistic and cultural preferences of target audiences. In the past, managing translations for websites and other digital content was a manual and time-consuming process. However, the advent of Translation Management Systems (TMS) revolutionized the way companies handle localization, significantly boosting efficiency and accuracy. This article provides an overview of TMS, its significance, and the benefits it offers to technology companies.',
        url: '/blog/tms-introduction',
        component: IntroductionToTms
    },
    {
        title: 'A Beginner\'s Guide to Automated Translation Services',
        summary: 'In today\'s globalized world, the need for efficient and accurate translation services is paramount. Businesses and individuals alike require translations for various purposes, such as websites, documents, and communication with international clients. Automated Translation Services have emerged as a valuable tool to streamline the translation process, offering quick and cost-effective solutions. This article serves as a beginner\'s guide to understanding automated translation services, their benefits, and how they are connected to Translation Management Systems (TMS).',
        url: '/blog/beginner-guide-to-tms',
        component: BeginnerGuideToTms
    },
    {
        title: 'An Overview of Popular Translation Formats: Comparing XLIFF and ICU',
        summary: 'Translation formats play a vital role in the localization industry, enabling efficient and accurate translation processes. Among the popular formats, XLIFF (XML Localization Interchange File Format) and ICU (International Components for Unicode) stand out as widely adopted choices. This article provides an overview of these formats, compares their features, and concludes why ICU is particularly well-suited for translations, especially in web development scenarios.',
        url: '/blog/formats-overview',
        component: OverviewOfFormats
    },
];
