import { UploadFile } from 'antd';

export function getUploadedFileContent(file: UploadFile): Promise<any> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const contents = e.target.result;
            try {
                const resourcesJson = JSON.parse(contents);
                resolve(resourcesJson);
            } catch (e) {
                reject(e);
            }
        };

        reader.readAsText(file as any);
    });
}
