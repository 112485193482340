// src/components/ChatGPTIntegration.tsx
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Typography } from 'antd';
import chatGtpDemo from '../assets/ChatGPTDemo.png'
import { LandingLayout } from './LandingLayout';
import { Helmet } from 'react-helmet';

const { Title, Paragraph } = Typography;

export const ChatGPTIntegration: React.FC = () => {
    const intl = useIntl();
    return (
        <LandingLayout>
            <Helmet>
                <title>
                    {intl.formatMessage({ id: "chatGptLanding.titlePage"})}
                </title>
                <meta
                    name="description"
                    content={intl.formatMessage({ id: "chatGptLanding.metaDescription"})}
                />
                <meta
                    name="keywords"
                    content={intl.formatMessage({ id: "chatGptLanding.metaKeywords"})}
                />
            </Helmet>
            <Title level={2}>
                <FormattedMessage id="chatGptLanding.title"/>
            </Title>
            <Paragraph>
                <FormattedMessage id="chatGptLanding.introduction"/>
            </Paragraph>
            <Title level={3}>
                <FormattedMessage id="chatGptLanding.benefits"/>
            </Title>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Title level={4}>
                        <FormattedMessage id="chatGptLanding.precision"/>
                    </Title>
                    <Paragraph>
                        <FormattedMessage id="chatGptLanding.precisionDescription"/>
                    </Paragraph>
                </Col>
                <Col xs={24} md={12}>
                    <Title level={4}>
                        <FormattedMessage id="chatGptLanding.speed"/>
                    </Title>
                    <Paragraph>
                        <FormattedMessage id="chatGptLanding.speedDescription"/>
                    </Paragraph>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Title level={4}>
                        <FormattedMessage id="chatGptLanding.customization"/>
                    </Title>
                    <Paragraph>
                        <FormattedMessage id="chatGptLanding.customizationDescription"/>
                    </Paragraph>
                </Col>
                <Col xs={24} md={12}>
                    <Title level={4}>
                        <FormattedMessage id="chatGptLanding.collaboration"/>
                    </Title>
                    <Paragraph>
                        <FormattedMessage id="chatGptLanding.collaborationDescription"/>
                    </Paragraph>
                </Col>
            </Row>
            <Title level={3}>
                <FormattedMessage id="chatGptLanding.screenshotTitle"/>
            </Title>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '2rem',
                }}
            >
                <img
                    src={chatGtpDemo}
                    alt="Locmat project screenshot"
                    style={{
                        maxWidth: '100%',
                        borderRadius: '5px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                />
            </div>
            <Paragraph>
                <FormattedMessage id="chatGptLanding.screenshotDescription"/>
            </Paragraph>
        </LandingLayout>
    );
};
